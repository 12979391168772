import { encryptData } from "@/utils/helperFunction";

const secretKey = "baseURL";

export const getBaseURL = (feature) => {
    let baseURL = "";

    switch (feature) {
        case "dashboard":
            baseURL = process.env.VUE_APP_METRICS_API_URL;
            break;
        case "media":
            baseURL = process.env.VUE_APP_CURATOR_API_URL;
            break;
        case "news":
            baseURL = process.env.VUE_APP_NEWS_API_URL;
            break;
        case "subscription":
            baseURL = process.env.VUE_APP_SUBSCRIPTION_API_URL;
            break;
        case "stories":
            baseURL = process.env.VUE_APP_STORIES_API_URL;
            break;
        default:
            baseURL = process.env.VUE_APP_INHOUSE_API_URL;
    }
    // Encrypt the base URL before returning
    const encryptedBaseURL = encryptData(baseURL, secretKey);
    return encryptedBaseURL;
};
