import { RequestGateway } from "@/store/RequestGateway";
import StorageUtil from "@/utils/StorageUtil";

export default {
    state: {
        isSessionCreateFail: false,
        currentSessionId: null,
        sessionList: [],
        sessionListTotalRow: 0,
        videoListBySessionId: [],
        videoListTotalRow: 0,
    },
    getters: {
        getIsSessionCreateFail: (state) => state.isSessionCreateFail,
        getCurrentSessionId: (state) => state.currentSessionId,
        sessionList: (state) => state.sessionList,
        getSessionListTotalRow: (state) => state.sessionListTotalRow,
        getVideoBySessionId: (state) => state.videoListBySessionId,
        getVideoListTotalRow: (state) => state.videoListTotalRow,
    },
    mutations: {
        IS_SESSION_CREATE_FAIL(state, val) {
            state.isSessionCreateFail = val;
        },
        UPDATE_CURRENT_SESSION_ID(state, id) {
            state.currentSessionId = id;
        },
        sessionList(state, data) {
            console.log("sateto session list", data);
            state.sessionList = data.sessionData;
            state.sessionListTotalRow = data.totalRow;
        },
        SET_VIDEO_BY_SESSION(state, data) {
            state.videoListBySessionId = data.reportData;
            state.videoListTotalRow = data.totalRow;
        },
    },
    actions: {
        async getAnalysisReport({ commit }, reportId) {
            const feature = "media";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/sessions/${reportId}/sessionReport`;

            try {
                const response = await RequestGateway.get(feature, url, {});
                return response;
            } catch (error) {
                return error;
            }
        },
        async getNewSessionList(
            { commit },
            { page = 1, perPage = 10, search = "", showLoading = false } = {}
        ) {
            if (showLoading) {
                commit("CHANGE_LOADING_STATUS", true);
            }

            const feature = "media";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/sessions?page=${page}&perPage=${perPage}&search=${encodeURIComponent(
                search
            )}`;

            try {
                const response = await RequestGateway.get(feature, url, {});
                if (response.data && response.data.length) {
                    commit("sessionList", {
                        sessionData: response.data,
                        totalRow: response.totalCount,
                    });
                    return response;
                }
            } catch (error) {
                commit("CHANGE_LOADING_STATUS", false);
                return error;
            } finally {
                if (showLoading) {
                    commit("CHANGE_LOADING_STATUS", false);
                }
            }
        },
        async downloadVideoDetailsBySession({ commit }, data) {
            const feature = "media";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/sessions/${data.sessionId}/videos/${data.videoId}/download`;

            try {
                const response = await RequestGateway.get(feature, url, {});
                return response;
            } catch (error) {
                return error;
            }
        },
        async videoDetailsBySessionOutput({ commit }, data) {
            const feature = "media";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/sessions/${data.sessionId}/videos/${
                data.videoId
            }/outputs?perPage=200`;

            commit("CHANGE_LOADING_STATUS", true);
            try {
                const response = await RequestGateway.get(feature, url, {});
                commit("CHANGE_LOADING_STATUS", false);
                return response;
            } catch (error) {
                commit("CHANGE_LOADING_STATUS", false);

                return error;
            }
        },
        async getVideoDetailsBySession(
            { commit },
            {
                page = 1,
                perPage = 10,
                search = "",
                showLoading = false,
                Id,
            } = {}
        ) {
            const feature = "media";
            const url = `/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/sessions/${Id}/videos?page=${page}&perPage=${perPage}&search=${encodeURIComponent(
                search
            )}`;

            try {
                const response = await RequestGateway.get(feature, url, {});

                if (response && response.data.length) {
                    commit("SET_VIDEO_BY_SESSION", {
                        reportData: response.data,
                        totalRow: response.totalCount,
                    });
                    return response;
                } else {
                    commit("SET_VIDEO_BY_SESSION", []);
                    return [];
                }
            } catch (error) {
                return error;
            }
        },
    },
};
