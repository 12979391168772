import axios from "axios";
import StorageUtil from "@/utils/StorageUtil";
import { showToast } from "@/constant/toast";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    (config) => {
        const token = StorageUtil.getSessionData("accessToken");
        const clientId = StorageUtil.getSessionData("currentOrganization");

        if (token) config.headers["Authorization"] = `Bearer ${token}`;
        if (clientId) config.headers["MainClientId"] = clientId;

        return config;
    },
    (error) => Promise.reject(error)
);

// Response interceptor to return `response.data` directly
axiosInstance.interceptors.response.use(
    (response) => response.data,
    (error) => {
        const errorMessage = error.response?.data?.error ;
        showToast("error", "Error", `${errorMessage}`);
        if (errorMessage === "unauthorized request") {
            sessionStorage.removeItem("accessToken");
            this.$router.push({ name: "login" });
        }
        console.log("error structure", error, errorMessage);
        return Promise.reject(error);
    }
);
export default axiosInstance;
