import { RequestGateway } from "@/store/RequestGateway";
import StorageUtil from "@/utils/StorageUtil";


export default {
    state: {
        activeSubscriptionDetails: null,
        activePlanDetails: null,
        planDetails: null,
        summaryDeatils: null,
        subcriptionSummaryLoading: true,
    },
    getters: {
        getActiveSubscriptionDetails(state) {
            return state.activeSubscriptionDetails;
        },
        getActivePlanDetails(state) {
            return state.activePlanDetails;
        },
        getPlanDetails(state) {
            return state.planDetails;
        },
        getSummaryDetails(state) {
            return state.summaryDeatils;
        },
        getSummaryLoading(state) {
            return state.subcriptionSummaryLoading;
        },
    },
    mutations: {
        SET_ACTIVE_SUBSCRIPTION_DETAILS(state, activeSubscriptionDetails) {
            state.activeSubscriptionDetails = activeSubscriptionDetails;
        },
        SET_ACTIVE_PLAN_DETAILS(state, activePlanDetails) {
            state.activePlanDetails = activePlanDetails;
        },
        SET_PLAN_DETAILS(state, planDetails) {
            state.planDetails = planDetails;
        },
        SET_SUMAARY_DETAILS(state, summaryDeatils) {
            state.summaryDeatils = summaryDeatils;
        },
        SET_SUBCRIPTION_SUMMARY_LOADING(state, summaryFlag) {
            state.subcriptionSummaryLoading = summaryFlag;
        },
    },
    actions: {
        async fetchBillingSummarry({ commit }) {
            const feature = "subscription";
            const url = `/api/v1/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/billing/summary`;
            try {
                const response = await RequestGateway.get(feature, url, {});
                if (response) {
                    commit("SET_SUMAARY_DETAILS", response);
                    return response;
                }
            } catch (error) {
                commit("SET_SUBCRIPTION_SUMMARY_LOADING", false);
                return error;
            } finally {
                commit("SET_SUBCRIPTION_SUMMARY_LOADING", false);
            }
        },
        // async fetchActivePlanDeatils({ commit }, planId) {
        //     const feature = "subscription";
        //     const url = `/api/v1/plans/${planId}`;
        //     try {
        //         const response = await RequestGateway.get(feature, url, {});
        //         commit("SET_ACTIVE_PLAN_DETAILS", response);
        //         return response;
        //     } catch (error) {
        //         return error;
        //     } finally {
        //         commit("CHANGE_LOADING_STATUS", false);
        //     }
        // },
        async cancelSubcription({ commit }, payload) {
            const feature = "subscription";
            const url = `/api/v1/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/subscriptions/cancel`;
            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    payload,
                    {}
                );
                return response;
            } catch (error) {
                return error;
            } finally {
                commit("CHANGE_LOADING_STATUS", false);
            }
        },
        async fetchPlans({ commit }, param) {
            const feature = "subscription";
            const url = `/api/v1/plans?isYearly=${param}`;
            try {
                const response = await RequestGateway.get(feature, url, {});

                commit("SET_PLAN_DETAILS", response.SubscriptionPlans);
                return response;
            } catch (error) {
                return error;
            } finally {
                commit("CHANGE_LOADING_STATUS", false);
            }
        },
        async upgradeSubcription({ commit }, payload) {
            const feature = "subscription";
            const url = `/api/v1/organizations/${StorageUtil.getSessionData(
                "currentOrganization"
            )}/subscriptions/upgrade`;

            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    payload,
                    {}
                );
                console.log('response', response); 
                return response;
            } catch (error) {
                return error;
            } finally {
                commit("CHANGE_LOADING_STATUS", false);
            }
        },
    },
};
