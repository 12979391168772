// RequestGateway.js
import axiosInstance from "@/store/axiosInstance";
import { getBaseURL } from "@/constant/requestHeader";
import { decryptData } from "@/utils/helperFunction";

export class RequestGateway {
  static get = (feature, url, options = {}) => {
    return requestGateway(feature, "get", url, options);
  };

  static post = (feature, url, body, options = {}) => {
    const updateOptions = { ...options, data: body };
    return requestGateway(feature, "post", url, updateOptions);
  };

  static put = (feature, url, body, options = {}) => {
    const updateOptions = { ...options, data: body };
    return requestGateway(feature, "put", url, updateOptions);
  };

  static delete = (feature, url, options = {}) => {
    return requestGateway(feature, "delete", url, options);
  };
}

const requestGateway = (feature, method, url, options) => {
  const secretKey = "baseURL";
  const encryptedBaseURL = getBaseURL(feature);
  const decryptedBaseURL = decryptData(encryptedBaseURL, secretKey);

  return axiosInstance.request({
    method,
    url,
    ...options,
    responseType: "json",
    baseURL: decryptedBaseURL,
    headers: {
      ...options.headers,
    },
    onDownloadProgress: options.onDownloadProgress,
  });
};
