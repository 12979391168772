import { RequestGateway } from "@/store/RequestGateway";
import StorageUtil from "@/utils/StorageUtil";
import { showToast } from "@/constant/toast";

export default {
    state: {
        newsEventList: [],
        WorldStories: [],
        filterData: [],
    },
    getters: {
        getNewsEvents: (state) => state.newsEventList,
        getWorldStories: (state) => state.WorldStories,
        getFilterData: (state) => state.filterData,
    },
    mutations: {
        GET_WORLD_STORIES(state, data) {
            state.WorldStories = data;
        },
        NEWS_EVENT_LIST(state, data) {
            state.newsEventList = data;
        },
        SET_FILTER_DATA(state, data) {
            state.filterData = data;
        },
    },
    actions: {
        async fetchClientNews({ commit }) {
            const feature = "news";
            const url = `clientnews?MainClientId=${StorageUtil.getSessionData(
                "currentOrganization"
            )}&EndpointType=q&MaximumArticles=10&specialt=8W1ZzUj-1xpYlUgI1BwgU`;

            try {
                const response = await RequestGateway.get(feature, url, {});

                commit("NEWS_EVENT_LIST", response);
            } catch (error) {
                console.log("error: " + error);
            } finally {
                commit("CHANGE_LOADING_STATUS", false);
            }
        },
        async fetchWorldStories({ commit }) {
            const feature = "news";
            const url = `worldnews?specialt=8W1ZzUj-1xpYlUgI1BwgU`;

            try {
                const response = await RequestGateway.get(feature, url, {});

                commit("GET_WORLD_STORIES", response);
            } catch (error) {
                return error;
            } finally {
                commit("CHANGE_LOADING_STATUS", false);
            }
        },
        async filterNews({ commit }, data) {
            const feature = "news";
            const url = `createFilter?MainClientId=${StorageUtil.getSessionData(
                "currentOrganization"
            )}&specialt=8W1ZzUj-1xpYlUgI1BwgU`;

            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    data,
                    {}
                );
            } catch (error) {
                return error;
            } finally {
                commit("CHANGE_LOADING_STATUS", false); // Assuming you have a loading status mutation
            }
        },
        async fetchFilterData({ commit }) {
            const feature = "news";
            const url = `newsFilters?MainClientId=${StorageUtil.getSessionData(
                "currentOrganization"
            )}&specialt=8W1ZzUj-1xpYlUgI1BwgU`;

            try {
                const response = await RequestGateway.get(feature, url, {});
                commit("SET_FILTER_DATA", response);
            } catch (error) {
                return error;
            } finally {
                commit("CHANGE_LOADING_STATUS", false);
            }
        },
        async deleteFilter({ commit }, recordId) {
            const feature = "news";
            const url = `deleteFilter?MainClientId=${StorageUtil.getSessionData(
                "currentOrganization"
            )}&RecordTypeId=${recordId}&specialt=8W1ZzUj-1xpYlUgI1BwgU`;

            try {
                const response = await RequestGateway.delete(feature, url, {});

                return response;
            } catch (error) {
                return error;
            }
        },
        async updateFilters({ commit }, data) {
            const feature = "news";
            const url = `createFilter?MainClientId=${StorageUtil.getSessionData(
                "currentOrganization"
            )}&specialt=8W1ZzUj-1xpYlUgI1BwgU`;

            try {
                const response = await RequestGateway.post(
                    feature,
                    url,
                    data,
                    {}
                );
            } catch (error) {
                return error;
            } finally {
                commit("CHANGE_LOADING_STATUS", false);
            }
        },
    },
};
