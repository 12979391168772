import { RequestGateway } from "@/store/RequestGateway";
import StorageUtil from "@/utils/StorageUtil";
import { showToast } from "@/constant/toast";

function normalize(inputArray) {
    const sum = inputArray.reduce((acc, num) => acc + num, 0);
    const average = sum / inputArray.length || 0;

    return inputArray.map((item) =>
        item >= average * 1.25 ? Math.floor(average * 1.25) + 1 : item + 1
    );
}

export default {
    state: {
        series: [],
        chartOptions: {},
        pieSeries: [],
        pieChartOptions: {},
        radarSeries: [
            {
                name: "Threats Detected",
                data: [],
            },
            {
                name: "Threats Searched",
                data: [],
            },
        ],
        radarChartOptions: {},
    },
    getters: {
        getSeries: (state) => state.series,
        getChartOptions: (state) => state.chartOptions,
        getPieSeries: (state) => state.pieSeries,
        getPieChartOptions: (state) => state.pieChartOptions,
        getRadarSeries: (state) => state.radarSeries,
        getRadarChartOptions: (state) => state.radarChartOptions,
    },
    mutations: {
        SET_SERIES(state, series) {
            state.series = series;
        },
        SET_CHART_OPTIONS(state, chartOptions) {
            state.chartOptions = chartOptions;
        },
        SET_PIE_SERIES(state, pieSeries) {
            state.pieSeries = pieSeries;
        },
        SET_PIE_CHART_OPTIONS(state, pieChartOptions) {
            state.pieChartOptions = pieChartOptions;
        },
        SET_RADAR_SERIES(state, radarSeries) {
            state.radarSeries = radarSeries;
        },
        SET_RADAR_CHART_OPTIONS(state, radarChartOptions) {
            state.radarChartOptions = radarChartOptions;
        },
    },
    actions: {
        async fetchData({ commit }) {
            const feature = "dashboard";
            const url = `/time_series_metrics`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                    MainClientId: StorageUtil.getSessionData(
                        "currentOrganization"
                    ),
                },
            };

            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
                if (response && response.time_series_metrics) {
                    const records = JSON.parse(response.time_series_metrics);

                    const categories = records.map(
                        (record) => record.creation_date
                    );
                    const sessioncount = records.map(
                        (record) => record.sessioncount
                    );
                    const videocount = records.map(
                        (record) => record.videocount
                    );
                    const objectcount = records.map(
                        (record) => record.objectcount
                    );

                    const series = [
                        { name: "Sessions Performed", data: sessioncount },
                        { name: "Videos Analyzed", data: videocount },
                        { name: "Threats Detected", data: objectcount },
                    ];

                    const chartOptions = {
                        chart: {
                            type: "area",
                            height: 350,
                            zoom: { enabled: false },
                            events: {},
                        },
                        dataLabels: { enabled: false },
                        stroke: { curve: "smooth" },
                        xaxis: {
                            type: "datetime",
                            categories: categories,
                            labels: { style: { colors: "#FFFFFF" } },
                            title: { style: { color: "#FFFFFF" } },
                        },
                        yaxis: {
                            title: {
                                text: "Counts",
                                style: { color: "#FFFFFF" },
                            },
                            labels: { style: { colors: "#FFFFFF" } },
                        },
                        tooltip: {
                            theme: "dark",
                            style: { fontSize: "12px" },
                            x: { format: "dd MMM yyyy" },
                        },
                        fill: {
                            type: "gradient",
                            gradient: {
                                shadeIntensity: 1,
                                inverseColors: false,
                                opacityFrom: 1,
                                opacityTo: 0.2,
                                stops: [0, 50, 100],
                            },
                        },
                        series: [
                            {
                                name: "Sessions Performed",
                                data: sessioncount,
                                fill: {
                                    gradient: {
                                        type: "vertical",
                                        shadeIntensity: 1,
                                        gradientToColors: [
                                            "rgba(244, 159, 124, 0.2)",
                                        ],
                                        opacityFrom: 1,
                                        opacityTo: 0.2,
                                        stops: [0, 100],
                                    },
                                },
                            },
                            {
                                name: "Videos Analyzed",
                                data: videocount,
                                fill: {
                                    gradient: {
                                        type: "vertical",
                                        shadeIntensity: 1,
                                        gradientToColors: [
                                            "rgba(129, 169, 244, 0.2)",
                                        ],
                                        opacityFrom: 1,
                                        opacityTo: 0.2,
                                        stops: [0, 100],
                                    },
                                },
                            },
                            {
                                name: "Threats Detected",
                                data: objectcount,
                                fill: {
                                    gradient: {
                                        type: "vertical",
                                        shadeIntensity: 1,
                                        gradientToColors: [
                                            "rgba(214, 83, 83, 0.2)",
                                        ],
                                        opacityFrom: 1,
                                        opacityTo: 0.2,
                                        stops: [0, 100],
                                    },
                                },
                            },
                        ],
                        colors: ["#F49F7C", "#81A9F4", "#D65353"],
                        legend: {
                            labels: {
                                colors: ["#FFFFFF", "#FFFFFF", "#FFFFFF"],
                            },
                            markers: {
                                fillColors: ["#F49F7C", "#81A9F4", "#D65353"],
                            },
                        },
                    };

                    commit("SET_SERIES", series);
                    commit("SET_CHART_OPTIONS", chartOptions);
                } else {
                    throw new Error("Invalid response data");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        },
        async fetchPieData({ commit }) {
            const feature = "dashboard";
            const url = `/pie_threat_metrics`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                    MainClientId: StorageUtil.getSessionData(
                        "currentOrganization"
                    ),
                },
            };

            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
                if (response && response.threat_metrics) {
                    const records = JSON.parse(response.threat_metrics);

                    const pieSeries = records.map(
                        (record) => record.DetectedCount
                    );

                    const pieChartOptions = {
                        labels: records.map(
                            (record) => record.FilledObjectName
                        ),
                        chart: {
                            type: "donut",
                        },
                        // colors: ['#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#FF5733', '#57FF33', '#A133FF'],
                        responsive: [
                            {
                                breakpoint: 480,
                                options: {
                                    chart: {
                                        width: 300,
                                    },
                                    legend: {
                                        position: "bottom",
                                    },
                                },
                            },
                        ],
                        fill: {
                            type: "gradient",
                        },
                        gradient: {
                            shade: "light",
                            type: "horizontal",
                            shadeIntensity: 0.25,
                            gradientToColors: undefined, // optional, if not defined - uses the shades of the same color in series
                            inverseColors: true,
                            opacityFrom: 0.85,
                            opacityTo: 0.85,
                            stops: [50, 0, 100],
                        },
                        stroke: {
                            show: false, // Hide the stroke
                            width: 0, // Set stroke width to 0
                        },
                        legend: {
                            labels: {
                                colors: "#FFFFFF",
                            },
                            fontSize: "14px",
                            fontFamily: "Arial, sans-serif",
                        },
                        dataLabels: {
                            style: {
                                colors: ["#FFFFFF"],
                                fontSize: "12px",
                            },
                            // enabled: false
                        },
                    };

                    commit("SET_PIE_SERIES", pieSeries);
                    commit("SET_PIE_CHART_OPTIONS", pieChartOptions);
                } else {
                    throw new Error("Invalid response data");
                }
            } catch (error) {
                console.error("Error fetching pie data:", error);
            }
        },
        async fetchRadarData({ commit }) {
            const feature = "dashboard";
            const url = `/radar_threat_metrics`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                    MainClientId: StorageUtil.getSessionData(
                        "currentOrganization"
                    ),
                },
            };

            try {
                const response = await RequestGateway.get(
                    feature,
                    url,
                    options
                );
                if (response && response.threat_metrics) {
                    const records = JSON.parse(response.threat_metrics);
                    const categories = records.map(
                        (record) => record.ObjectName
                    );
                    const searchCount = normalize(
                        records.map((record) => record.SearchCount)
                    );
                    const detectedCount = normalize(
                        records.map((record) => record.DetectedCount)
                    );

                    const radarSeries = [
                        { name: "Threats Detected", data: detectedCount },
                        { name: "Threats Searched", data: searchCount },
                    ];

                    const radarChartOptions = {
                        chart: {
                            toolbar: { show: false },
                            dropShadow: {
                                enabled: false,
                                blur: 8,
                                left: 1,
                                top: 1,
                                opacity: 0.2,
                            },
                        },
                        legend: {
                            show: true,
                            fontSize: "14px",
                            labels: { colors: "#CBD5E1" },
                        },
                        yaxis: { show: false },
                        colors: ["#D65353", "#81A9F4"],
                        xaxis: { categories: categories },
                        fill: { opacity: [1, 0.8] },
                        stroke: { show: false, width: 0 },
                        markers: { size: 0 },
                        grid: { show: false },
                    };

                    commit("SET_RADAR_SERIES", radarSeries);
                    commit("SET_RADAR_CHART_OPTIONS", radarChartOptions);
                } else {
                    throw new Error("Invalid response data");
                }
            } catch (error) {
                console.error("Error fetching radar data:", error);
            }
        },
        async updateProfile({ commit }, { payload, userId }) {
            const feature = "inhouse";
            const url = `/updateProfile`;
            const options = {
                headers: {
                    Authorization: `Bearer ${StorageUtil.getSessionData(
                        "accessToken"
                    )}`,
                },
                data: payload,
            };

            try {
                const response = await RequestGateway.put(
                    feature,
                    url,
                    payload,
                    options
                );
                if (response.success === true) {
                    showToast(
                        "success",
                        "Success",
                        "User profile updated successfully"
                    );
                }
                return response;
            } catch (error) {
                return error;
            }
        },
    },
};
