// globalToast.js
import { useToast } from 'primevue/usetoast';

let toastInstance = null;

export function setToastInstance(toast) {
  toastInstance = toast;
}

export function showToast(severity, summary, detail, life = process.env.VUE_APP_TOAST_LIFE_TIME) {
  if (toastInstance) {
    toastInstance.add({ severity, summary, detail, life });
  } else {
    console.warn('Toast instance not set. Make sure to initialize it in your App.');
  }
}
