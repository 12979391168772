class StorageUtil {
    // Save data to local storage
    static setLocalData(key, value) {
        if (typeof window !== "undefined") {
            try {
                localStorage.setItem(key, btoa(value));
            } catch (error) {
                console.error('Error setting data in local storage:', error);
            }
        }
    }

    // Get data from local storage
    static getLocalData(key) {
        if (typeof window !== "undefined") {
            try {
                const encodedValue = localStorage.getItem(key);
                return encodedValue ? atob(encodedValue) : null;
            } catch (error) {
                console.error('Error getting data from local storage:', error);
                return null;
            }
        }
    }

    // Remove data from local storage
    static removeLocalData(key) {
        if (typeof window !== "undefined") {
            try {
                localStorage.removeItem(key);
            } catch (error) {
                console.error('Error removing data from local storage:', error);
            }
        }
    }

    // Save data to session storage
    static setSessionData(key, value) {
        if (typeof window !== "undefined") {
            try {
                sessionStorage.setItem(key, btoa(value));
            } catch (error) {
                console.error('Error setting data in session storage:', error);
            }
        }
    }

    // Get data from session storage
    static getSessionData(key) {
        if (typeof window !== "undefined") {
            try {
                const encodedValue = sessionStorage.getItem(key);
                return encodedValue ? atob(encodedValue) : null;
            } catch (error) {
                console.error('Error getting data from session storage:', error);
                return null;
            }
        }
    }

    // Remove data from session storage
    static removeSessionData(key) {
        if (typeof window !== "undefined") {
            try {
                sessionStorage.removeItem(key);
            } catch (error) {
                console.error('Error removing data from session storage:', error);
            }
        }
    }
}

export default StorageUtil;
